.header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid;
    margin-bottom: 20px;
}

.status[data-status="requested"] {
    background-color: #FFD2B6 !important;
}


.dashboardtable {
    display: flex;
    flex-direction: column;
    /* margin-left: 12%;
    margin-right: 5%; */
    margin: 3%;
    width: 100%;
    margin-top: 2%;
}

.table-container {
    width: 100%;
    padding: 10px;
}

.dropdowncss {
    margin-left: 2%;
}

.search-container {
    display: flex;
    /* justify-content: space-between; */
    margin-bottom: 10px;
}

#search-input {
    padding: 5px;
    width: 200px;
}

#dropdown-select {
    padding: 5px;
    width: 150px;
}

#data-table,
#expanded-table {
    width: 100%;
    margin-top: 10px;
}

#data-table th,
#data-table td,
#expanded-table th,
#expanded-table td {
    padding: 8px;
    text-align: left;
}

#data-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

#data-table tr:nth-child(even),
#expanded-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

#data-table tr:hover,
#expanded-table tr:hover {
    background-color: #e9e9e9;
}

.expand-trigger {
    cursor: pointer;
    margin-right: 5px;
}


.quotebtncss1 {
    background: linear-gradient(90deg, #FF731D 99.99%, #C5E14B 100%) !important;
    border: none;
    width: auto;
    font-family: "Roboto-bold";
    border-radius: 24px;
    font-size: 14px;
}

.disabled {
    background: rgba(0, 0, 0, 0.2) !important;
    border: none;
    width: auto;
    font-family: "Roboto-bold";
    border-radius: 24px;
    font-size: 14px;
    color: #00000066 !important;
}

.quotebtncss {
    background: linear-gradient(90deg, #FF731D 99.99%, #C5E14B 100%) !important;
    border: none;
    width: auto;
    font-family: "Roboto-bold";
    border-radius: 24px;
    margin-top: 10px;
    font-size: 14px;
}

.quotebtncss2 {
    background: #34A853 !important;
    border: none;
    width: auto;
    font-family: "Roboto-bold";
    border-radius: 24px;
    margin-top: 10px;
    font-size: 10px;
}

.headertext {
    font-family: 'Roboto-bold';
    font-size: 24px;
}


.tablebtn {
    display: flex;
    flex-direction: row;
}

.tableborder {
    border: 1px solid rgba(186, 186, 186, 1)
}

.projectnamecss {
    border: 1px solid rgba(186, 186, 186, 1);
    width: 16%
}

.tdBackgroundColor {
    background-color: rgba(245, 245, 245, 1) !important;
}

.editimage {
    margin: 1%;
    cursor: pointer;
    height: 14px
}

.upimage {
    margin: 1%;
    cursor: pointer;
    height: 6px
}

.quotebtncss3 {
    background: #FF1D1D !important;
    border: none;
    width: auto;
    font-family: "Roboto-bold";
    border-radius: 24px;
    margin-top: 10px;
    font-size: 10px;
    margin-right: 2px;
}



.ordercount{
    background-color: #34A853;
    border-radius: 15px;
    padding-left: 7px;
    padding-right: 7px;
    position: absolute;
    color: white;
    margin-bottom: 7px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-size: 15px;
    position: absolute;  
}
.chatcss {
    background: #2F80ED;
    border: none;
    width: auto;
    font-family: "Roboto-bold";
    border-radius: 24px;
    margin-top: 10px;
    font-size: 14px;
    margin-left: 2px;
}