
  ul li p {
    padding: 0;
    margin: 0;
    display: inline;
}
.activeButton{
  border: none;
  color: #fff;
  background-color: #FF731D;
  border-radius: 20px;
  margin:8px;
  padding:5px;
  padding-left: 15px;
  padding-right: 15px;
}
.nonActiveButton{
  border: none;
  background-color: #fff;
  border-radius: 20px;
}