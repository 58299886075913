.btn-order{
    background-color: rgba(255, 115, 29, 1);
    color: white;
    border: none;
    padding: 3px 30px;
}

.btn-order-inactive{
    background-color: rgba(255, 115, 29, 0.5);
    color: white;
    border: none;
    padding: 3px 30px;
}

.status-dashboard-bar{
    display: flex;
    
    
}

.status-dashboard{
    width: 210px;
    display: flex;
    
}

.exportimage {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.topsearchbar{margin-left: initial
}

.quotebtncss {
    background: linear-gradient(90deg, #FF731D 99.99%, #C5E14B 100%) !important;
    border: none;
    width: auto;
    font-family: "Roboto-bold";
    border-radius: 24px;
    margin-top: 0px !important;
    font-size: 14px;
}

table {
    border-spacing: 0px;
    table-layout: fixed;
    margin-left: auto;
    margin-right: auto;
}


td {
    word-wrap: break-word;
}
.modal.show .modal-dialog {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    transform: none;
    border-radius: 0;
}
.modal-header .btn-close button{
    color:#fff
}

.modal-header .btn-close {
    padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
    margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto;
    padding-right: 20px !important;
    color: #ffff !important;
}
.col-search{
    position: relative;
}
.input-container {
    border: solid 1px rgb(206,212,218);
    border-radius: 2px;
    position: absolute;
    display: flex;
    align-items: center;
    padding:0 4px;
    gap: 4px;
  }
  .input-container .fa-search {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .fa-search {
    color:rgb(206,212,218);
    height: 16px;
    line-height: 36px;
  }
  
  .search-form{
    border: none;
      outline: none;
  }
 

  .textcss {
    display: flex;
    justify-content: flex-end;
    color: #FF731D;
    cursor: pointer;
}

.t-completed{

    border: 1px solid rgba(52, 168, 83, 1);
    background-color: rgba(52, 168, 83, 1);
    padding: 2px 2px;
    border-radius: 15px;
    color: #fff;
}
.t-in-transit{
    border: 1px solid rgba(255, 193, 7, 1);
    background-color: rgba(255, 193, 7, 1);
    padding: 2px 2px;
    border-radius: 15px;
    color: #fff
}
.t-new-order{
    border: 1px solid rgba(255, 115, 29, 1);
    background-color: rgba(255, 115, 29, 1); 
 padding: 2px 2px;
 border-radius: 15px;
 color: #fff
}

