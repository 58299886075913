.headercss {
    background-color: #1746A2;
    color: #EDEFF5;
    width: 100%;
}

.image {
    height: 20px;
    margin: 4%;
    cursor: pointer;
}

.modalcss {
    height: 25rem;
    overflow-y: scroll;
    overflow-x: hidden;
}

.markimage {
    height: 10px;
    margin: 4%;
    cursor: pointer;
}

.userimage {
    height: 20px;
    margin: 4%;
    cursor: pointer;
}

.hoverdata span:hover {
    background-color: #FFFFFF;
    cursor: pointer;
}
.clicked span {
    background-color: #FFFFFF !important; /* Change the background color when marked as read */
    color: #555; /* Adjust text color for readability */
    text-decoration: line-through; /* You can strikethrough the text, if desired */
    cursor: default; /* Remove pointer cursor to indicate it's not clickable again */
  }

.notification-toast {
    position: fixed;
    top: 20px;
    right: 20px;
}

.notificationHeader {
    margin-left: 50px;
    margin-top: 20px;
    font-size: 16px;
    font-family: 'Roboto-Regular'
}

.notificationcss {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bagecount {
    border: 1px solid #FF731D;
    background-color: white;
    border-radius: 15px;
    padding-left: 2px;
    padding-right: 2px;
    height: 15px;
    color: #000000;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-size: 12px;
    margin-top: 6px;
    position: absolute;
    margin-left: 22px;
    padding-bottom: 2px;
}

@media (min-width: 576px) {
    .modalnotification {
        max-width: var(--bs-modal-width);
        margin-right: 1% !important;
        margin-left: 57% !important;
        margin-top: 4.5% !important;
    }
}

/* .headercss .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 70% !important;
    color: var(--bs-modal-color);
    pointer-events: auto;
    background-color: #DADADA;
    background-clip: padding-box;
    border: #DADADA;
    border-radius: var(--bs-modal-border-radius);
    outline: 0;
}

.headercss .modalcontainer {
    display: flex;
    align-items: flex-start;
    margin-bottom: 5%;
    flex-direction: column
}

.headercss .modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 0px !important;
    border-bottom: 1px solid #BEBEBE !important;
    border-top-left-radius: var(--bs-modal-inner-border-radius);
    border-top-right-radius: var(--bs-modal-inner-border-radius);

}

.headercss .modalHader {
    display: flex;
    align-items: flex-start;
    margin-bottom: 5%;
    flex-direction: row;
    width: 100%
} */

.notificationtext {
    font-size: 14px;
    color: #000000;
    margin-bottom: 2%;
    margin-left: 5%;
    font-family: 'Roboto-bold'
}

.markread {
    font-size: 14px;
    color: #FF731D;
    margin-bottom: 2%;
    margin-left: 25%;
    cursor: pointer;
    width: 50%;
    font-family: 'Roboto-bold'
}

.lielement {
    font-size: 14px;
    display: flex;
    align-items: flex-start;
    margin-bottom: 2%;
    flex-direction: row;
    width: 100%;
    border-bottom: 1px solid rgba(23, 70, 162, 1)
}

.lielementText {
    font-size: 14px;
    display: flex;
    align-items: flex-start;
    margin-bottom: 2%;
    flex-direction: column;
    width: 200px;
}

.settings {
    font-size: 14px;
    color: #FF731D;
    margin-top: 2%;
    width: 50%;
    font-family: 'Roboto-bold';
    cursor: pointer;
}


.notsetting {
    font-size: 20px;
    margin-top: 5%;
    display: flex;
    align-items: flex-start;
    margin-bottom: 2%;
    flex-direction: row;
    width: 100%;
    border-bottom: '1px solid #C7C7C7'
}

.notsettingText {
    font-size: 14px;
    display: flex;
    align-items: flex-start;
    margin-bottom: 2%;
    flex-direction: column;
    width: 100%;
}




.btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 0em !important;
    padding: 0.25em 0.25em;
    color: #000;

    opacity: .9 !important;
}

.form-check-input:checked {
    background-color: #FF731D;
    border-color: #FF731D;
}

.backbtn {
    width: 124px;
    height: 27px;
    border-radius: 7px;
    border: 1px solid #B5B2B2;
}

.donebtn {
    background-color: #FF731D;
    width: 124px;
    height: 27px;
    border-radius: 7px;
    margin-left: 5%;
    border: 1px solid #FF731D;
}

.selectcss {
    width: 69px;
    height: 24px;
    background: #D9D9D9;
    border: 1px solid #B5B2B2;
    border-radius: 10px;
}

.form-switch .form-check-input {
    width: 3.5em !important;

}

.form-check-input {
    height: 1.3em !important;
}

.notsetting select {
    margin: 0;
    font-family: inherit;
    font-size: 12px;
    line-height: inherit;
}