

@font-face {
    font-family: 'Roboto-bold';
    src: local('Roboto-Bold'), url(./assets/fonts/Roboto-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto-Regular';
    src: local('Roboto-Regular'), url(./assets/fonts/Roboto-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto-Medium';
    src: local('Roboto-Medium'), url(./assets/fonts/Roboto-Medium.ttf) format('truetype');
}

textarea {
    resize: none;
  }
.form-control.is-valid, .was-validated .form-control:valid {
    border-color: #198754;
    padding-right: 0rem !important;
    background-image: none !important;
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.mainCard {
    display: flex;
    flex: 1;
    /* margin-bottom: 2%; */
    /* height: 100vh; */
}

.mainCardDashboard {
    display: flex;
    flex: 1;
    /* margin-bottom: 2%; */
    /* height: 100vh; */
}
.backcolor {
    background-color: #1746A2;
}
input::-ms-reveal{
    display:none;
  }
.logowidth{
    width: 100px;
}

.btncss {
    background: linear-gradient(90deg, #FF731D 99.99%, #C5E14B 100%);
    border: none;
    width: 100%;
    font-family: "Roboto-bold";
}

.mainheader {
    font-family: 'Roboto-bold';
}

.labelcss {
    font-family: 'Roboto-Medium';
}

.cardcss {
    width: 28rem;
    background-color: #DADADA;
    text-align: left;
    box-shadow: 0px 4px 5px 0px #4B4B4B;
    display: flex
}

.navtext {
    color: #EDEFF5;
}

.form-label {
    font-family: 'Roboto-Medium';
    font-size: 14px;
}

.btn {
    font-family: 'Roboto-Regular' !important;
    font-size: 14px;
}

#data-table th, #data-table td, #expanded-table th, #expanded-table td {
    font-family: 'Roboto-Regular';
    font-size: 14px;

}

#data-table th {
    font-family: 'Roboto-bold';
    font-size: 14px;
}
@media (max-width:991px) {
    .cardcss {
        width: 28rem;
        background-color: #DADADA;
        text-align: left;
        box-shadow: 0px 4px 5px 0px #4B4B4B;
        display: flex
    }

}

@media (max-width:650px) {
    .cardcss {
        width: 20rem;
        background-color: #DADADA;
        text-align: left;
        box-shadow: 0px 4px 5px 0px #4B4B4B;
        display: flex
    }
}

@media (max-width: 480px) {
    .cardcss {
        width: 15rem;
        background-color: #DADADA;
        text-align: left;
        box-shadow: 0px 4px 5px 0px #4B4B4B;
        display: flex
    }
}

@media (max-width:290px) {
    .cardcss {
        width: 15rem;
        background-color: #DADADA;
        text-align: left;
        box-shadow: 0px 4px 5px 0px #4B4B4B;
        display: flex
    }
}