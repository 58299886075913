.accordian-btn{
    background-color: rgba(218, 232, 253, 1);
    justify-content: end;
}
.collapsed{
    background-color: rgba(218, 232, 253, 1);
}
.faq-item{
    background-color:rgba(218, 232, 253, 1);
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.25);

}
.accordian-item{
    display: flex;
    justify-content: space-between;
}
.contact-us-image{
    width: 500px;
    height: 550px;
    padding: 30px;
    border-right: 1px solid black;
}
.contact-us-form{
    width: 70%;
}
.name-contact{
    width:100%
}
.header-underline{
    padding-bottom: 10px;
    border-bottom: 2px solid rgba(23, 70, 162, 1);
}
.contact-us-fields{
    border: none;
    border-bottom: 1px solid black;
    border-radius: 0;
    margin: 15px 0;
}
.about-us-image-a{
   width: 350px;
   height:450px 
}
.about-us-image-b{
   width: 300px;
   height:300px 
}
.about-us-content{
 font-size: 18px;   
}
.banner-main{
    z-index: -999;
}
.banner-main-img{
    width: 100%;
    height: 600px;
    object-fit: cover;
    filter: brightness(0.5) saturate(0%);
}
.banner-content{
    color:#fff;
    padding-top: 250px;
}
