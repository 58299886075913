.side-menu-icon{
  width:25px;
  object-fit:contain;
}
ul li p {
  padding: 0;
  margin: 0;
  display: inline;
}



.sidebar {
    width: 380px;
    background-color: #f2f2f2;
    transition: width 0.3s ease;
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .collapsed {
    width: 50px;
  }
  
  .toggle-button {
    border: none;
    background-color: transparent;
    font-size: 24px;
    padding: 10px;
    cursor: pointer;
  }
  
  .menu {
    list-style-type: none;
    padding: 0;
    flex-grow: 1;
    overflow-y: auto;
  }
  
  .menu li {
    padding: 10px;
  }
  