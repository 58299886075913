
/* .kep-login-facebook {
    color: transparent !important;
    cursor: pointer !important;
    font-size: calc(.27548vw + 16.71074px) !important;
    background-color: #DADADA !important;
    border: 0px #DADADA !important;
    padding: 0px !important;
} */
.eyeicon{
    
    position: absolute;
    right: 22px;
    transition-duration: 0s;
    top: 320px;

}
/* .fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #4c69ba !important;
} */
.lgimageflex {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

}

.googleimage {
    margin-left: 10px;
    height: 22px;
    cursor: pointer;
}

.lgimage {
    height: 25px;
    margin: 4%;
    cursor: pointer;
}

.signupcss {
    text-decoration: underline;
    font-family: 'Roboto-bold';
    cursor: pointer;
}


a {
    color: #1c1d1e;
    text-decoration: underline;
}