.password-main{
    margin-top: 20px;
}
.col-search{
    position: relative;
    
}
.input-container-pass {
    border: solid 1px rgb(206,212,218);
    width: 400px;
    border-radius: 2px;
    position: absolute;
    display: flex;
    align-items: center;
    padding:0 4px;
    gap: 4px;
  }
  .input-container .fa-search {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .fa-search {
    color: #000;;
    height: 16px;
    line-height: 36px;
  }
  
  .search-form{
    border: none;
      outline: none;
  }
 
