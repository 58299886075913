.header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid;
    margin-bottom: 20px;
}

.contractcount{
    background-color: #34A853;
    border-radius: 15px;
    padding-left: 7px;
    padding-right: 7px;
    position: absolute;
    color: white;
    margin-top: 7px;

    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-size: 15px;
    position: absolute;
   
}


.active-button {
    background-color: #FF731D;
    color: white;
    border: none;
    cursor: pointer;
    width: 250px;
    padding-top: 0.5rem!important;
    padding-bottom: 0.5rem!important;
    padding-right: 1rem!important;
    padding-left: 1rem!important;
    margin-top: 1rem!important;
    margin-bottom: 1rem!important;
    text-transform: uppercase;

  }
  
  .inactive-button {
    background-color: rgba(255, 115, 29, 0.5);
    color: white;
    border: none;
    cursor: pointer;
    width: 250px;
    padding-top: 0.5rem!important;
    padding-bottom: 0.5rem!important;
    padding-right: 1rem!important;
    padding-left: 1rem!important;
    margin-top: 1rem!important;
    margin-bottom: 1rem!important;
    text-transform: uppercase;
  }

  /* .contractstatus[contract-status="REQUESTED"] {
    background-color: #FFD2B6 !important;
} */


.dashboardtable {
    display: flex;
    flex-direction: column;
    /* margin-left: 12%;
    margin-right: 5%; */
    margin: 3%;
    width: 100%;
    margin-top: 2%;
}

.table-container {
    width: 100%;
    padding: 10px;
}

.dropdowncss{
    margin-left: 2%;
}

.search-container {
    display: flex;
    /* justify-content: space-between; */
    margin-bottom: 10px;
}

#search-input {
    padding: 5px;
    width: 200px;
}

#dropdown-select {
    padding: 5px;
    width: 150px;
}

#data-table,
#expanded-table {
    width: 100%;
    margin-top: 10px;
}

#data-table th,
#data-table td,
#expanded-table th,
#expanded-table td {
    padding: 8px;
    text-align: left;
}

#data-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

#data-table tr:nth-child(even),
#expanded-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

#data-table tr:hover,
#expanded-table tr:hover {
    background-color: #e9e9e9;
}

.expand-trigger {
    cursor: pointer;
    margin-right: 5px;
}


.quotebtncss1 {
    background: linear-gradient(90deg, #FF731D 99.99%, #C5E14B 100%) !important;
    border: none;
    width: auto;
    font-family: "Roboto-bold";
    border-radius: 24px;
    font-size: 14px;
}

.quotebtncss {
    background: linear-gradient(90deg, #FF731D 99.99%, #C5E14B 100%) !important;
    border: none;
    width: auto;
    font-family: "Roboto-bold";
    border-radius: 24px;
    margin-top: 10px !important;
    font-size: 14px;
}

.requestcss{
    background: rgba(255, 0, 0, 1) !important;
    border: none;
    width: auto;
    font-family: "Roboto-bold";
    border-radius: 24px;
    margin-top: 10px !important;
    font-size: 14px;
}

.quotebtncss2 {
    background: #34A853 !important;
    border: none;
    width: auto;
    font-family: "Roboto-bold";
    border-radius: 24px;
    margin-top: 10px;
    font-size: 14px;
    margin-top: 10px;
}

.headertext{
    font-family: 'Roboto-bold';
    font-size: 24px;
}


.tablebtn{
    display: flex;
    flex-direction: row;
}

.tableborder{
    border: 1px solid rgba(186, 186, 186, 1)
}
.projectnamecss{
    border: 1px solid rgba(186, 186, 186, 1);
    width:16%

}

.tdBackgroundColor{
    background-color: rgba(245, 245, 245, 1) !important;
}

.editimage{
    margin: 1%;  cursor: pointer;height:auto !important
}

.upimage{
    margin: 1%;  cursor: pointer;height:6px
}


.quotebtncss3 {
    background: #FF1D1D !important;
    border: none;
    width: auto;
    font-family: "Roboto-bold";
    border-radius: 24px;
    margin-top: 10px;
    font-size: 14px;
}

.chatcss{
    background: #2F80ED;
    border: none;
    width: auto;
    font-family: "Roboto-bold";
    border-radius: 24px;
    margin-top: 10px;
    font-size: 14px;
    margin-left: 2px;
}

.chatcss1{
    background: #2F80ED;
    border: none;
    width: auto;
    font-family: "Roboto-bold";
    border-radius: 24px;
    margin-top: 10px;
    font-size: 14px;
    margin-left: 38px;
}

.tdcss{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}