.profile-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    border-bottom: 1px solid #BABABA;
}

.profiletext{
    font-family: 'Roboto-bold';
    font-size: 24px;
   
}
.my-datepicker {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ced4da;
    width: 100%
}

.react-datepicker-wrapper {
    display: flex !important;
}
.profileclass{
    display: flex;
    flex-direction: column;
    margin: 3%;
    width: 100%;
    margin-top: 2%;
    
}
.textcontainer {
    background-color: #F2EEEE;
}
.profile-container .table-container {
    display: flex;
    flex-direction: row;
}

.form1 {
    display: flex;
    flex: 1;
}

.header-profile {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 2%;
}

 .profileclass .quotebtncss {
    background: linear-gradient(90deg, #FF731D 99.99%, #C5E14B 100%) !important;
    border: none;
    width: auto;
    font-family: "Roboto-bold";
    border-radius: 24px;
    margin-top: 10px;
    margin-left: 2px;
}




.profileclass .profiletextcss{
    font-family: 'Roboto-bold';
    font-size: 16px;
    margin-left: 10px;
}

.cameraimage{
  
    background-color: white;
   
    padding-left: 24px;
    padding-right: 0px;
    height: 15px;
    color: #000000;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-size: 12px;
    margin-top: 0px;
    position: absolute;
    margin-left: 22px;
    padding-bottom: 15px;
}