.eyeiconp{
    
    position: absolute;
    right: 30px;
    transition-duration: 0s;
    top: 490px;

}
.eyeiconcp{
    
    position: absolute;
    right: 30px;
    transition-duration: 0s;
    top: 402px;

}

