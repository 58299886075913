.uploadimage {
  width: 90px;
  height: 78px;
}

.imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: grey; */
  border: 1px dotted;
  background: linear-gradient(0deg, rgba(194, 201, 233, 0.3), rgba(201, 205, 223, 0.3)),
    linear-gradient(0deg, #F8F8FF, #F8F8FF);

}


.uploadcard{
  box-shadow: 10px 10px 8px 10px #d3cccc7a;

}

.uploadedPhoto{
  display: flex;
  align-items: center;
  justify-content: center;;
}