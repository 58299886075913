.sidenavbar {
    display: flex;
    flex-direction: column;
    margin: 3%;
    width: 100%;
    margin-top: 2%;
}

.startdate {
    display: flex;
    flex-direction: column;
}

.quotationContainer .header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
   
    margin-bottom: 20px;
   
}

.templatecss{
    margin-bottom: 2%;
}

.infotext{
    font-family: 'Roboto-bold';
    font-size: 16px;
    margin-bottom: 2%;
}
.templatetext{
    margin-bottom: 2%;
    margin-top: 2%;
    display: flex;
    justify-content: center;
    font-family: 'Roboto-bold';
    font-size: 14px;
}

.headertext{
    font-family: 'Roboto-bold';
    font-size: 24px;
}

.subContainer {
    display: flex;
    display: flex;
    flex-direction: column;
}

.mainContainer {
    display: flex;
    flex-direction: column;
    margin: 3%;
    width: 100%;
    margin-top: 2%;
}

.form1 {
    display: flex;
    flex: 1.5;
}

.form2 {
    display: flex;
    flex: 1;
}

.mainContainer .dashboardtable {
    display: flex;
    flex-direction: row;
}

.mainContainer .table-container {
    display: flex;
    flex-direction: row;
}

.textcss {
    display: flex;
    justify-content: flex-end;
    color: #FF731D;
}

.commentcss{
    display: flex;
    justify-content: flex-start;
    color: #FF731D;
    cursor: pointer;
}
.my-datepicker {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ced4da;
    width: 107%
}

.textcontainer {
    background-color: #F2EEEE;
}

.templatecontainer{
    background-color: #F2EEEE;
    height: 25vh;
}

.textcontainer1 {
    background-color: #fff;
    margin-top: 5%;
    border-left: 5px solid #FF731D;
}

.mainContainer .quotebtncss {
    background: linear-gradient(90deg, #FF731D 99.99%, #C5E14B 100%) !important;
    border: none;
    width: auto;
    font-family: "Roboto-bold";
    border-radius: 24px;
    margin-top: 10px;
}

.mainContainer .submitbtncss {
    background: #34A853 !important;
    border: none;
    width: auto;
    font-family: "Roboto-bold";
    border-radius: 24px;
    margin-left: 2px;
    margin-top: 10px;
    text-transform: uppercase;
}

.comment{
    margin-bottom: 2%;
}
.downloadimage{
    margin: 1%;  cursor: pointer;height:14px
}


  /* Styles for the table */
  #table-container {
    margin-bottom: 20px;
  }
  
  #pagination-container {
    text-align: center;
  }
  
  .headertext1{
    font-family: 'Roboto-bold';
    font-size: 16px;
}
  #pagination {
    display: inline-block;
    padding: 0;
    margin: 0;
  }
  
  #pagination li {
    display: inline;
    margin-right: 5px;
  }
  
  #pagination li a {
    padding: 5px 10px;
    border: 1px solid #ddd;
    background-color: #f9f9f9;
    color: #888;
    text-decoration: none;
  }
  
  #pagination li.active a {
    background-color: #4CAF50;
    color: white;
  }
  