* {
    font-family: 'Source Sans Pro';
  }
  .DateTimeRangeMainContainer{
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .vSaperater {
    margin-bottom: 95px !important;
  }
  
  .standaloneDateTimeRangeContainer {
    width: 600px;
    box-shadow: 0px 0px 10px rgba(41, 41, 41, 0.2);
    border-radius: 4px;
    opacity: 1;
  }
  
  .standaloneDateTimeRangeContainerHidden {
    opacity: 0;
  }
  
  .notifier {
    margin: unset;
  }
  .dtrButtonContainer {
    display: none;
}
.timeSelectContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #DADADA;
}
.optionsInputList{
    font-size: 12px;
    padding: 4px;
}
.dtrApplyButton{
    background-color:#FF731D !important ;
    border: 1px solid #FF731D;
    border:none
}
.dtrCancelButtonU{
    background-color:#fff !important ;
    border:none;
    font-size: 14px;
    padding:5px;
    border-radius:5px
}
.dtrApplyButtonU{
    background-color:#FF731D !important ;
    border:none;
    color:#fff;
    border-radius: 2px;
    font-size: 14px;
    padding:5px;
    border-radius:5px
}
.buttonContainer{
    background-color: #DADADA;
}
.calendarDetails{
    color:#494E50
}
.calendar-header{
    color:#BDC0CC
}