.completed{
    background-color: rgba(52, 168, 83, 1);
    padding: 2px 5px;
}
.pending{
    background-color: rgba(255, 193, 7, 1);
    padding: 2px 5px;
}
/* .active{
    background-color: rgb(23, 70, 162);
    padding: 2px 5px;
} */
.p-completed{
    background-color: rgba(52, 168, 83, 1);
    padding: 2px 5px;
}
.p-pending{
    background-color: rgba(255, 193, 7, 1);
    padding: 2px 5px;
}
.p-active{
    background-color: rgb(23, 70, 162);
    padding: 2px 5px;
}


.modal.show .modal-dialog {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    transform: none;
    border-radius: 0;
}
.modal-header .btn-close button{
    color:#fff
}

.modal-header .btn-close {
    padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
    margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto;
    padding-right: 20px !important;
    color: #ffff !important;
}

.textcss {
    display: flex;
    justify-content: flex-end;
    color: #FF731D;
    cursor: pointer;
}

.combinemodal .textback{
    background-color: rgba(242, 238, 238, 1);

}

/* Form.Control{
    background-color: rgba(242, 238, 238, 1);
} */