.group-message-screen {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .group-header {
    background-color: #075e54;
    color: #fff;
    padding: 16px;
    font-size: 20px;
  }
  
  .message-list {
    flex: 1;
    list-style-type: none;
    padding: 16px;
    overflow-y: auto;
  }
  
  .message {
    margin-bottom: 16px;
    padding: 8px;
    border-radius: 8px;
  }
  
  .incoming {
    background-color: #f5f5f5;
    align-self: flex-start;
  }
  
  .outgoing {
    background-color: #dcf8c6;
    align-self: flex-end;
  }
  
  .sender {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 4px;
  }
  
  .content {
    font-size: 16px;
  }
  
  .timestamp {
    font-size: 12px;
    color: #888;
  }
  
  .compose {
    display: flex;
    align-items: center;
    padding: 8px;
    background-color: #f5f5f5;
  }
  
  .message-input {
    flex: 1;
    padding: 8px;
    border-radius: 20px;
    border: none;
    outline: none;
    font-size: 16px;
  }
  
  .send-button {
    margin-left: 8px;
    padding: 8px 16px;
    border-radius: 20px;
    border: none;
    background-color: #075e54;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
  }
  
  .send-button:hover {
    background-color: #044c3d;
  }
  