/* .dashboardtable{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 2%;
    z-index: -9999;
} */
.table-borderless td,
.table-borderless th {
    border: 0
}

.dashboardflex {
    display: flex;
    flex-direction: row;
    justify-content: space-around !important;
}

.dashboardimage {
    margin-left: 10px;
    height: 60px;
    cursor: pointer;
}

.dashboardbody{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around !important;
}

.dashboardcard {
    width: 200px;
    text-align: left;
    box-shadow: 0px 4px 5px 0px #4B4B4B;
    border-radius: 20px;
    margin-right: 20px;
}

.txtcss{
    font-family: 'Roboto-regular';
    font-size: 15px;
}
.numbercss{
    font-family: 'Roboto-bold';
    font-size: 22px;
}