.sidenavbar {
    display: flex;
    flex-direction: column;
    margin: 3%;
    width: 100%;
    margin-top: 2%;
}

.startdate {
    display: flex;
    flex-direction: column;
}

.quotationContainer .header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
   
    margin-bottom: 20px;
   
}

.headertext{
    font-family: 'Roboto-bold';
    font-size: 24px;
}

.headertext1{
    font-family: 'Roboto-bold';
    font-size: 16px;
}

.headertext2{
    font-family: 'Roboto-Medium';
    font-size: 14px;
}

.subContainer {
    display: flex;
    display: flex;
    flex-direction: column;
}

.mainContainer {
    display: flex;
    flex-direction: column;
    margin: 3%;
    width: 100%;
    margin-top: 2%;
}

.form1 {
    display: flex;
    flex: 1.5;
}

.form2 {
    display: flex;
    flex: 1;
}

.mainContainer .dashboardtable {
    display: flex;
    flex-direction: row;
}

.mainContainer .table-container {
    display: flex;
    flex-direction: row;
}

.textcss {
    display: flex;
    justify-content: flex-end;
    color: #FF731D;
}

.commentcss{
    display: flex;
    justify-content: flex-start;
    color: #FF731D;
    cursor: pointer;
}
.my-datepicker {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ced4da;
    width: 107%
}

.textcontainer {
    background-color: #F2EEEE;
}

.textcontainer1 {
    background-color: #fff;
    border-left: 5px solid #FF731D;
    margin-top: 5%;
}

.mainContainer .quotebtncss {
    background: linear-gradient(90deg, #FF731D 99.99%, #C5E14B 100%) !important;
    border: none;
    width: auto;
    font-family: "Roboto-bold";
    border-radius: 24px;
    margin-top: 10px;
}

.mainContainer .submitbtncss {
    background: #34A853 !important;
    border: none;
    width: auto;
    font-family: "Roboto-bold";
    border-radius: 24px;
    margin-left: 2px;
    margin-top: 10px;
}

.comment{
    margin-bottom: 2%;
    font-family: 'Roboto-bold';
    font-size: 20px;
}