.modal-table{
    width: 100%;
}
.modal-body-QR{
    
    background-color: rgba(255, 255, 255, 1);
}
.col-search{
    position: relative;
}
.search-form{
  border: none;
    outline: none;
}
.input-container {
    border: solid 1px rgb(206,212,218);
    border-radius: 2px;
    position: absolute;
    display: flex;
    align-items: center;
    padding:0 4px;
    gap: 4px;
  }
  .input-container .fa-search {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .fa-search {
    color:rgb(206,212,218);
    height: 16px;
    line-height: 36px;
  }
  

  input[type="text-area"]{
    resize:none
  }

.text-css{
    display: flex;
    justify-content: flex-end;
    color: #FF731D;
    cursor: pointer;
}

